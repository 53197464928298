<template>
    <a-select 
        class="w-full auth_select" 
        size="large" 
        ref="selectRef"
        :value="selVal"
        :loading="loading"
        :filter-option="false"
        :default-active-first-option="false"
        :placeholder="placeholder"
        showSearch
        :maxTagCount="1"
        :mode="multiple ? 'multiple' : 'default'"
        @search="searchHandler"
        @popupScroll="getDataScrollHandler"
        @change="selectElem">
        <div slot="notFoundContent" class="flex justify-center">
            <a-empty :image="simpleImage" description="Нет данных" />
        </div>
        <a-select-option v-for="item in list" :key="item.id" :value="item.id">
            {{ item.name }}
        </a-select-option>
        <div slot="dropdownRender" slot-scope="menu">
            <div v-if="loading" slot="notFoundContent" class="flex justify-center p-2">
                <a-spin size="small" />
            </div>
            <v-nodes v-if="!loading" :vnodes="menu" />
            <a-divider class="m-0" />
            <div class="p-2">
                <a-spin :spinning="scrollLoading" size="small">
                    <div class="flex justify-end w-full">
                        <a-button type="ui" ghost block size="small" @click="closeSelect()">
                            {{$t('close')}}
                        </a-button>
                    </div>
                </a-spin>
            </div>
        </div>
    </a-select>
</template>

<script>
import { Empty } from 'ant-design-vue'
let searchTimer;
export default {
    components: {
        VNodes: {
            functional: true,
            render: (h, ctx) => ctx.props.vnodes,
        }
    },
    props: {
        value: {
            type: [String, Number, Array],
        },
        apiUrl: {
            type: String,
            required: true
        },
        region: {
            type: String,
            required: true
        },
        placeholder: {
            type: String,
            default: ''
        },
        multiple: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            simpleImage: null,
            selVal: this.multiple ? [] : null,
            page: 1,
            loading: false,
            scrollLoading: false,
            next: true,
            page_size: 15,
            list: [],
            text: ''
        }
    },
    computed: {
        selectRef() {
            return this.$refs['selectRef']
        },
    },
    created(){
        this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE
        this.getDataHandler()
    },
    watch:{
        value(val){
            this.selVal = val
        }
    },
    methods: {
        closeSelect() {
            this.$nextTick(() => {
                this.selectRef.$refs.vcSelect.setOpenState(false)
            })
        },
        searchHandler(value) {
            clearTimeout(searchTimer)
            searchTimer = setTimeout(() => {
                this.page = 1
                this.next = true
                this.list = []
                this.text = value
                this.getDataHandler()
            }, 500)
        },
        selectElem(val) {
            this.$emit('input', val)
        },
        async getDataHandler() {
            const params = {
                page: this.page,
                page_size: this.page_size,
                region: this.region
            }
            if(this.text) {
                params.text = this.text
            }
            try {
                this.loading = true
                const { data } = await this.$http.get(this.apiUrl, { params })
                if(data) {
                    this.next = data.next
                    this.list = this.list.concat(data.results)
                }
            } catch(e) {
                console.log(e)
            } finally {
                this.loading = false
            }
        },
        async getDataScrollHandler(event) {
            if(this.next) {
                let target = event.target
                if(this.next && !this.loading && !this.scrollLoading && target.scrollTop + target.offsetHeight === target.scrollHeight) {
                    this.page = this.page + 1

                    const params = {
                        page: this.page,
                        page_size: this.page_size,
                        region: this.region
                    }
                    if(this.text) {
                        params.text = this.text
                    }
                    try {
                        this.scrollLoading = true

                        const { data } = await this.$http.get(this.apiUrl, { params })
                        if(data) {
                            this.next = data.next
                            this.list = this.list.concat(data.results)
                        }
                    } catch(e) {
                        console.log(e)
                    } finally {
                        this.scrollLoading = false
                    }
                }
            }
        }
    }
}
</script>

<style lang="scss">
.auth_select{
    .ant-select-selection__rendered{
        line-height: 50px;
    }
    .ant-select-selection--single{
        height: 50px;
    }
    .ant-select-selection--multiple{
        max-height: 50px;
        padding-bottom: 0px;
    }
}
</style>