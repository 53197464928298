<template>
    <div class="user_info">
        <a-form-model
            ref="authForm"
            :model="form"
            layout="vertical"
            autocomplete="off"
            :rules="rules">
            <a-form-model-item
                ref="view_in_dat_pad"
                class="w-full mb-2"
                label="ФИО в дательном падеже (кому)"
                prop="view_in_dat_pad">
                <a-input
                    v-model="form.view_in_dat_pad"
                    placeholder="ФИО в дательном падеже"
                    class="w-full"
                    size="large"
                    @pressEnter="formSubmit()" />
            </a-form-model-item>
            <a-form-model-item
                ref="view_in_dat_pad_kaz"
                class="w-full mb-2"
                label="ФИО в дательном падеже на казахском языке (кімге)"
                prop="view_in_dat_pad_kaz">
                <a-input
                    v-model="form.view_in_dat_pad_kaz"
                    placeholder="ФИО в дательном падеже на казахском языке"
                    class="w-full"
                    size="large"
                    @pressEnter="formSubmit()" />
            </a-form-model-item>
            <div class="grid_col_2">
                <a-form-model-item
                    ref="birthday"
                    class="w-full mb-2"
                    label="Дата рождения"
                    prop="birthday">
                    <a-date-picker 
                        v-model="form.birthday"
                        class="w-full"
                        format="DD.MM.YYYY"
                        :showToday="false"
                        placeholder="Дата рождения"
                        size="large" />
                </a-form-model-item>
                <a-form-model-item
                    ref="phone"
                    class="w-full mb-2"
                    label="Телефон"
                    prop="phone">
                    <a-input
                        v-model="form.phone"
                        placeholder="Введите телефон"
                        class="w-full"
                        size="large"
                        @pressEnter="formSubmit()" />
                </a-form-model-item>
            </div>
            <div class="grid_col_2">
                <a-form-model-item
                    ref="iin"
                    class="w-full mb-2"
                    label="ИИН"
                    prop="iin">
                    <a-input
                        v-model="form.iin"
                        placeholder="Введите телефон"
                        class="w-full"
                        size="large"
                        @pressEnter="formSubmit()" />
                </a-form-model-item>
                <a-form-model-item
                    ref="sex"
                    class="w-full mb-2"
                    label="Пол"
                    prop="sex">
                    <AuthSelect v-model="form.sex" placeholder="Пол" apiUrl="/ehub/sexes/" :region="region" />
                </a-form-model-item>
            </div>
            <a-row :gutter="24">
                <a-col :span="windowWidth >= 828 ? 12 : 24">
                    <a-form-model-item
                        ref="school"
                        class="w-full mb-2"
                        label="Текущее место работы"
                        prop="school">
                        <AuthSelect v-model="form.school" placeholder="Текущее место работы" apiUrl="/ehub/alma_maters/" :region="region" />
                    </a-form-model-item>
                </a-col>
                <a-col :span="windowWidth >= 828 ? 12 : 24">
                    <a-form-model-item
                        ref="positions"
                        class="w-full mb-2"
                        label="Основная должность"
                        prop="positions">
                        <AuthSelect v-model="form.positions" placeholder="Основная должность" apiUrl="/ehub/positions/" :region="region" />
                    </a-form-model-item>
                </a-col>
            </a-row>
            <div class="grid_col_2">
                <a-form-model-item
                    ref="date"
                    class="w-full mb-2"
                    label="Дата трудоустройства"
                    prop="date">
                    <a-date-picker 
                        v-model="form.date"
                        class="w-full"
                        format="DD.MM.YYYY"
                        :showToday="false"
                        placeholder="Дата трудоустройства"
                        size="large" />
                </a-form-model-item>
                <a-form-model-item
                    ref="teacher_languages"
                    class="w-full mb-2"
                    label="Язык преподавания"
                    prop="teacher_languages">
                    <AuthSelect v-model="form.teacher_languages" multiple placeholder="Язык преподавания" apiUrl="/ehub/languages/" :region="region" />
                </a-form-model-item>
            </div>
            <a-form-model-item
                ref="avatar"
                class="w-full mb-2"
                label="Фото"
                prop="avatar">
                <div class="flex justify-center relative">
                    <label for="avatar_upload" class="cursor-pointer avatar_select truncate">
                        <div class="input_label truncate">
                            <div v-if="form.avatar" class="truncate">{{form.avatar.name}}</div>
                            <div v-else class="no_select truncate">Прикрепите фото</div>
                        </div>
                        <i class="fi fi-rr-clip" />
                    </label>
                            
                    <input
                        type="file"
                        id="avatar_upload"
                        style="display:none;"
                        ref="avatarUpload"
                        v-on:change="handleFileChange"
                        accept=".jpg, .jpeg, .png, .gif" />
                      
                </div>
            </a-form-model-item>

            <!--<a-form-model-item
                ref="job_title"
                class="w-full mb-2"
                label="Ваша должность"
                prop="job_title">
                <a-input
                    v-model="form.job_title"
                    placeholder="Введите вашу должность"
                    class="w-full"
                    size="large"
                    @pressEnter="formSubmit()" />
            </a-form-model-item>

            <a-form-model-item
                ref="agreement"
                class="w-full mb-2"
                prop="agreement">
                <a-checkbox v-model="form.agreement">
                    Согласен <a :href="authConfig.agreement" target="_blank">с политикой конфиденциальности</a>
                </a-checkbox>
            </a-form-model-item>-->

            <div>
                <!--<a-button 
                    size="large"
                    ghost
                    type="primary"
                    @click="$store.commit('user/SET_REG_STEP', 2)">
                    Назад
                </a-button>-->
                <a-button
                    class="button_next"
                    type="primary"
                    block
                    :loading="loading"
                    size="large"
                    @click="formSubmit()">
                    Зарегистрироваться
                </a-button>
            </div>
        </a-form-model>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import AuthSelect from '@/components/AuthSelect.vue'

export default {
    components: {
        AuthSelect
    },
    props: {
        regForm: {
            type: Object,
            default: () => {}
        },
        saveForm: {
            type: Object,
            default: () => {}
        },
        userForm: {
            type: Object,
            default: () => null
        },
        region: {
            type: String,
            default: ''
        },
        resetRegForm: {
            type: Function,
            default: () => {}
        }
    },
    computed: {
        ...mapState({
            user: state => state.user.user,
            dbList: state => state.dbList,
            serverType: state => state.serverType,
            pushAuth: state => state.pushAuth,
            config: state => state.config.config,
            windowWidth: state => state.windowWidth
        }),
        authConfig() {
            return this.$store.state.user.authConfig
        },
    },
    created() {
        if(this.userForm) {
            this.form = {
                ...this.form,
                ...this.userForm
            }
        }
    },
    data() {
        return {
            loading: false,
            nextLoading: false,
            form: {
                sex: null,
                birthday: null,
                teacher_languages: [],
                date: null,
                positions: null,
                school: null,
                first_name: '',
                last_name: '',
                patronymic_name: '',
                iin: '',
                phone: '',
                post: '',
                contractor_name: 'Заглушка',
                job_title: '',
                agreement: true,
                view_in_dat_pad: '',
                view_in_dat_pad_kaz: '',
                avatar: null
            },
            rules: {
                avatar: [
                    { required: true, message: this.$t('field_required'), trigger: 'change' }
                ],
                sex: [
                    { required: true, message: this.$t('field_required'), trigger: 'change' }
                ],
                date: [
                    { required: true, message: this.$t('field_required'), trigger: 'change' }
                ],
                positions: [
                    { required: true, message: this.$t('field_required'), trigger: 'change' }
                ],
                school: [
                    { required: true, message: this.$t('field_required'), trigger: 'change' }
                ],
                birthday: [
                    { required: true, message: this.$t('field_required'), trigger: 'change' }
                ],
                view_in_dat_pad: [
                    { required: true, message: this.$t('field_required'), trigger: 'change' },
                    { max: 255, message: this.$t('required_sym', { sym: 255 }), trigger: 'change' }
                ],
                view_in_dat_pad_kaz: [
                    { required: true, message: this.$t('field_required'), trigger: 'change' },
                    { max: 255, message: this.$t('required_sym', { sym: 255 }), trigger: 'change' }
                ],
                teacher_languages: [
                    { required: true, message: this.$t('field_required'), trigger: 'change' }
                ],
                iin: [
                    { required: true, message: this.$t('field_required'), trigger: 'change' },
                    { max: 12, message: 'Максимум 12 символов', trigger: 'change' },
                    { min: 12, message: 'Минимум 12 символов', trigger: 'change' }
                ],
                phone: [
                    {
                        pattern: /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/,
                        message: ('Номер заполнен некорректно'), trigger: 'change'
                    },
                    { required: true, message: this.$t('field_required'), trigger: 'change' },
                ],
                agreement: [
                    { required: true, message: this.$t('field_required'), trigger: 'change', 
                        validator: (rule, value, callback) => value },
                ]
            }
        }
    },
    methods: {
        handleFileChange(event) {
            const file = Object.values(event.target.files)[0]
            if(file) {
                this.form.avatar = file
            }
        },
        nextStep() {
            location.reload()
        },
        formSubmit() {
            this.$refs.authForm.validate(async valid => {
                if (valid) {
                    try {
                        this.loading = true
                        let fData = new FormData()
                        const queryData = JSON.parse(JSON.stringify(this.form))

                        let response = null

                        const formData = {
                            ...this.regForm,
                        }

                        fData.append("avatar", this.form.avatar)
                        fData.append("password", this.saveForm.password)
                        fData.append("password1", this.saveForm.password_confirm)
                        fData.append("first_name", queryData.first_name )
                        fData.append("last_name", queryData.last_name )
                        fData.append("patronymic_name", queryData.patronymic_name )
                        fData.append("iin", queryData.iin )
                        fData.append("phone", queryData.phone )
                        // fData.append("teacher_languages", queryData.teacher_languages )
                        fData.append("view_in_dat_pad_kaz", queryData.view_in_dat_pad_kaz )
                        fData.append("view_in_dat_pad", queryData.view_in_dat_pad )
                        fData.append("birthday", this.$moment(queryData.birthday).format('YYYY-MM-DD') )
                        fData.append("school", queryData.school )
                        fData.append("positions", queryData.positions )
                        fData.append("date", this.$moment(queryData.date).format('YYYY-MM-DD') )
                        fData.append("sex", queryData.sex )

                        fData.append("captcha", this.regForm.captcha )
                        fData.append("confirm_token", this.regForm.confirm_token )
                        fData.append("target", this.regForm.target )
                        fData.append("login", this.regForm.login )
                        fData.append("email", this.regForm.login )
                        fData.append("region", this.region )

                        for (let i = 0; i < queryData.teacher_languages.length; i++) {
                            fData.append('teacher_languages', queryData.teacher_languages[i])
                        }
                        // fData.append("join_organization", this.regForm.join_organization )

                        formData.password = this.saveForm.password
                        formData.password_confirm = this.saveForm.password_confirm

                        formData.first_name = queryData.first_name 
                        formData.last_name = queryData.last_name 
                        formData.patronymic_name = queryData.patronymic_name 
                        formData.iin = queryData.iin 
                        formData.phone = queryData.phone 
                        formData.teacher_languages = queryData.teacher_languages
                        formData.view_in_dat_pad_kaz = queryData.view_in_dat_pad_kaz
                        formData.view_in_dat_pad = queryData.view_in_dat_pad
                        formData.birthday = queryData.birthday
                        formData.school = queryData.school
                        formData.positions = queryData.positions
                        formData.date = queryData.date
                        formData.sex = queryData.sex

                        const { data } = await this.$http.post('/ehub/register/', fData, {
                            headers: {
                                "Content-Type": "multipart/form-data"
                            }
                        })
                        if(data) {
                            //localStorage.setItem('user', JSON.stringify(data))
                            //this.$store.commit('user/SET_USER', data)
                            this.resetRegForm()
                            this.$store.commit('user/SET_REG_STEP', 4)
                        }
                        /*response = await this.$http.post('/users/intro/', queryData)
                        data = response.data
                        if(data) {
                            localStorage.setItem('user', JSON.stringify(data))
                            this.$store.commit('user/SET_USER', data)
                            this.$store.commit('user/SET_REG_STEP', 4)
                        }*/
                    } catch(error) {
                        console.log(error)
                        if(error.message) {
                            this.$message.error(error.message, 4)
                        }
                        if(!error.message) {
                            this.$message.error(JSON.stringify(error), 4)
                        }
                    } finally {
                        this.loading = false
                    }
                } else {
                    return false
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
$breakpoint-1: 828px;

.grid_col_3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0 1.5rem;
    @media (max-width: $breakpoint-1) {
        grid-template-columns: repeat(1, 1fr);
    }

}
.grid_col_2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 1.5rem;
    @media (max-width: $breakpoint-1) {
        grid-template-columns: repeat(1, 1fr);
    }
}
.grid_col_width_2{
    display: grid;
    grid-template-columns: repeat(2, 50%);
    gap: 0 1.5rem;
    @media (max-width: $breakpoint-1) {
        grid-template-columns: repeat(1, 1fr);
    }
}
.employees_radio{
    &::v-deep{
        .ant-radio-wrapper{
            .ant-radio{
                display: none;
            }
            span{
                padding: 2px 8px;
                border: 1px solid var(--borderColor);
                border-radius: 30px;
                display: inline-block;
                background: #e3e6ea;
                cursor: pointer;
                -moz-user-select: none;
                -webkit-user-select: none;
                user-select: none;
                transition: all .3s cubic-bezier(.645,.045,.355,1);
            }
            &.ant-radio-wrapper-checked{
                span{
                    background: var(--primaryHover);
                    color: var(--blue);
                }
            }
        }
    }
}
.reg_form{
    @media (max-height: 750px) {
        padding-top: 110px;
    }
}

.buttons_grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    @media (max-width: $breakpoint-1) {
        grid-template-columns: repeat(2, 1fr);
    }
}
.button_next {
    grid-column: span 2 / span 2;
    @media (max-width: $breakpoint-1) {
        grid-column: span 1 / span 1;
    }
}
.user_info {
    &::v-deep {
        .ant-input,
        .ant-btn {
            height: 50px;
        }
    }
}
.avatar_select{
    height: 50px;
    padding: 6px 11px;
    font-size: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid #e1e7ec;
    border-radius: 6px;
    justify-content: space-between;
    .no_select{
        opacity: 0.6;
    }
    i{
        color: #0e8945;
        margin-left: 15px;
    }
}
</style>